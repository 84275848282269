<template>
  <div class="ptf-list">
    <div class="begin mb-6 cursor-pointer">
      <div class="cont border-2 rounded-lg p-4">
        <div class="delete flex justify-end" @click.stop="$emit('openConfirmation', ptfLists.id)">
          <svg-icon
              name="ic_delete-red"
              original
              class="w-4 h-4"
          />
        </div>
        <div class="flex">
          <div class="part-1 mr-4">
            <div
                class="image h-10 w-12 rounded-lg"
                v-bind:style="{ 'background-image': 'url(' + ptfPicture + ')' }"
            ></div>
          </div>
          <div class="part-2">
            <div class="name text-black">{{ ptfLists.label }}</div>
            <div class="place mt-4 flex items-center">
              <div class="icon mr-4">
                <svg-icon
                    name="ic_location"
                    original
                    class="w-3 h-3"
                />
              </div>
              <div class="title text-black font-light text-sm">{{ truncateString(ptfLists.location, 20) }}</div>
            </div>
            <div class="place mt-4 flex items-center">
              <div class="icon mr-4">
                <svg-icon
                    name="ic_clarity_phone-handset-solid"
                    original
                    class="w-3 h-3"
                />
              </div>
              <div class="title text-black font-light text-sm">
                <div>{{ ptfLists.phone }}</div>
              </div>
            </div>
            <div class="place mt-4 flex items-center">
              <div class="icon mr-4">
                <svg-icon
                    name="ic_comment"
                    original
                    class="w-3 h-3"
                />
              </div>
              <div class="title text-black font-light text-sm">
                <div v-if="ptfLists.email === ''">-</div>
                <div v-else>{{ truncateString(ptfLists.email, 20) }}</div>
              </div>
            </div>
            <div class="place mt-4 flex items-center hidden">
              <div class="icon mr-4">
                <svg-icon
                    name="ic_lock-password-fill"
                    original
                    class="w-3 h-3"
                />
              </div>
              <div class="title text-black font-light text-sm">
                <div v-if="ptfLists.body.access === ''">-</div>
                <div v-else>{{ truncateString(ptfLists.body.access, 20) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'ptf-list',
  props: {
    ptfLists: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      ptfPicture: ''
    }
  },
  created () {
    this.getSSRPicture()
    console.log(this.ptfLists)
  },
  methods: {
    getSSRPicture () {
      http.get(apiRoute.baseURL + apiRoute.getFileById + this.ptfLists.logoId, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.ptfPicture = response.file
      }).catch(error => {
        console.log(error)
      })
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .image {
    background-image: url("../../../assets/images/img_card.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
