<template>
  <div class="point-member-detail">
    <popup-compte>
      <div class="begin bg-white p-8 pb-24 h-screen overflow-y-scroll">
        <div class="header flex items-center">
          <div class="icon mr-6 cursor-pointer">
            <svg-icon
              name="ic_go-back"
              original
              class="w-8"
              @click="goBack"
            />
          </div>
          <div class="back text-black font-medium text-lg">
            {{ $t('agentInformation') }}
          </div>
        </div><br>
        <div class="body mt-8">
          <div class="infos flex items-center">
            <div class="part-1 border-r w-1/2">
              <div class="profile rounded-full w-12 h-12">
                <svg-icon
                  name="ic_user"
                  original
                  class="w-12 h-12 rounded-full"
                />
              </div><br>
              <div class="name font-semibold text-black">
                <div v-if="memberDetailInfos.firstName === ''">{{ memberDetailInfos.pseudo }}</div>
                <div v-else>{{ memberDetailInfos.firstName + ' ' + memberDetailInfos.lastName }}</div>
              </div><br>
              <div class="phone flex items-center">
                <div class="icon mr-3">
                  <svg-icon
                    name="ic_phone"
                    original
                    class="w-3 h-3"
                  />
                </div>
                <div class="num text-black text-sm">
                  <div v-if="memberDetailInfos.phone === null">-</div>
                  <div v-else>{{ memberDetailInfos.phone }}</div>
                </div>
              </div>
            </div>
            <div class="part-2 w-1/2 pl-12">
              <div class="profession">
                <div class="flex">
                  <svg-icon
                    name="ic_briefcase"
                    original
                    class="w-4 h-4 mr-4"
                  />
                  <div>
                    <div class="pro-title mb-2 text-sm">{{ $t('profession') }}</div>
                    <div class="pro-res text-black">{{ memberDetailInfos.specialite }}</div>
                  </div>
                </div>
              </div><br>
              <div class="profession">
                <div class="flex">
                  <svg-icon
                    name="ic_hospital"
                    original
                    class="w-4 h-4 mr-4"
                  />
                  <div>
                    <div class="pro-title mb-2 text-sm">{{ $t('hospital') }}</div>
                    <div class="pro-res text-black">{{ memberDetailInfos.hospitalName }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div><br>
          <div class="services mt-12">
            <div class="service-title text-black font-medium">{{ $t('offeredServices') }}</div><br>
            <div class="service-content text-black">
              <div v-if="memberDetailInfos.offeredServices === null">
                {{ $t('noOfferedServices') }}
              </div>
              <div v-else-if="memberDetailInfos.offeredServices.length === 0">
                {{ $t('noOfferedServices') }}
              </div>
              <div v-else>
                <div
                  v-for="(item, i) in memberDetailInfos.offeredServices"
                  :key="i"
                  class="c-1 ml-6 mb-4"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="documents mt-12 invisible">
            <div class="service-title text-black font-medium">{{ $t('documents') }}</div><br>
            <div class="images flex items-center">
              <div
                class="im-1 rounded-lg w-1/2 h-40 mr-2"
                v-bind:style="{ 'background-image': 'url(' + cniFile + ')' }"
              >
              </div>
              <div
                class="im-2 rounded-lg w-1/2 h-40 ml-2"
                v-bind:style="{ 'background-image': 'url(' + professionFile + ')' }"
              ></div>
            </div>
          </div><br>
          <div v-if="showButton" class="boutons flex items-center mt-6 mb-24">
            <div
              class="cancel w-1/2 mr-2 rounded-lg text-center font-medium p-6 cursor-pointer"
              @click="invalidDemand"
            >
              {{ $t('invalidateMembershipButton') }}
            </div>
            <div
              class="valid w-1/2 ml-2 rounded-lg text-center font-medium p-6 text-white cursor-pointer"
              @click="validDemand"
            >
              {{ $t('validateMembershipButton') }}
            </div>
          </div>
        </div>
      </div>
    </popup-compte>
  </div>
</template>

<script>
import PopupCompte from '../../../components/helper/add/popup-compte'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'point-member-detail',
  components: { PopupCompte },
  props: {
    showButton: {
      type: Boolean,
      default: true
    },
    memberDetailInfos: Object
  },
  data () {
    return {
      message: '',
      successAdd: false,
      cniFile: '',
      professionFile: '',
      imgBase: 'data:image/jpeg;base64,'
    }
  },
  created () {
    this.getFileCni()
    this.getFile()
  },
  methods: {
    invalidDemand () {
      http.post(apiRoute.baseURL + apiRoute.validationDemand, {
        id: this.memberDetailInfos.id,
        isValid: false
      }).then(response => {
        console.log(response)
        this.message = this.$t('rejectNewMembershipSSR')
        this.successAdd = true
      }).catch(error => {
        console.log(error)
      })
    },
    validDemand () {
      http.post(apiRoute.baseURL + apiRoute.validationDemand, {
        id: this.memberDetailInfos.id,
        isValid: true
      }).then(response => {
        console.log(response)
        this.message = this.$t('validNewMembershipSSR')
        this.successAdd = true
      }).catch(error => {
        console.log(error)
      })
    },
    getFileCni () {
      http.get(apiRoute.baseURL + apiRoute.getFileById + this.memberDetailInfos.cniFileId)
        .then(response => {
          // console.log(response)
          if (response.file.substr(0, 23) === this.imgBase) {
            this.cniFile = response.file
          } else {
            this.cniFile = this.imgBase + response.file
          }
        }).catch(error => {
          console.log(error)
        })
    },
    getFile () {
      http.get(apiRoute.baseURL + apiRoute.getFileById + this.memberDetailInfos.professionFileId)
        .then(response => {
          // console.log(response)
          if (response.file.substr(0, 23) === this.imgBase) {
            this.professionFile = response.file
          } else {
            this.professionFile = this.imgBase + response.file
          }
        }).catch(error => {
          console.log(error)
        })
    },
    closeSuccess () {
      this.successAdd = false
      window.location.reload()
    },
    goBack () {
      this.$emit('closeMembershipDetail', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .pro-title {
    color: $pass_gray;
  }
  .im-1 {
    background-image: url("../../../assets/images/img_card.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .im-2 {
    background-image: url("../../../assets/images/img_medoc.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .valid {
    background: $pass_green_button;
  }
  .cancel {
    color: $pass_red;
    background: rgba(196,58,43,0.15);
  }
</style>
