<template>
  <div class="is-ptf">
    <div class="begin">
      <div class="search-bloc">
        <div class="recherche-name">
          <div class="relative recherche justify-center items-center pl-8 pr-4 py-4 border bg-white rounded">
            <div class="icon-search absolute inset-y-0 left-0 flex items-center px-4">
              <svg-icon
                name="ic_search"
                original
                class="icon w-4 h-4"
              />
            </div>
            <div class="search-input ml-4">
              <label>
                <input
                  v-model="search"
                  type="text"
                  :placeholder="$t('searchPTF')"
                  class="recherche-input"
                >
              </label>
            </div>
          </div>
        </div>
      </div><br>
      <div class="loading" :class="{ 'not_empty': isLoading === false }">
        <loading
          :active="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
          loader="dots"
          color="#0B5382"
          background-color="transparent"
        />
      </div>
      <div
        v-if="nothing && !isLoading"
        class="no-student text-center text-lg py-32"
      >
        {{ $t('noPTF') }}
      </div>
      <div v-if="!isLoading">
        <div
          v-for="(item, i) in sortedArrayUser"
          :key="i"
          class="ptf"
          @click="changePosition(i)"
        >
          <div :class="{ 'selected_ptf': position === i }">
            <ptf-list
              :ptf-lists="item"
              @openConfirmation="openConfirmation"
            />
          </div>
        </div>
      </div>
    </div>

    <confirm-delete-point v-if="isConfirm" :point-id="pointId" @goBack="goBack" @openSuccessDelete="openSuccessDelete"></confirm-delete-point>

    <success-add v-if="isSuccess" message="Point SSR supprimé avec succès" button-label="Retour" @closeSuccess="closeSuccess"></success-add>
  </div>
</template>

<script>
import PtfList from './ptf-list'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ConfirmDeletePoint from '@/components/helper/points/confirm-delete-point.vue'
import SuccessAdd from '@/components/helper/success-add.vue'
export default {
  name: 'is-ptf',
  components: { SuccessAdd, ConfirmDeletePoint, Loading, PtfList },
  data () {
    return {
      search: '',
      position: 0,
      ptfs: [],
      isLoading: true,
      fullPage: false,
      nothing: false,
      coordinate: {
        lat: null,
        lng: null
      },
      isConfirm: false,
      pointId: null,
      isSuccess: false
    }
  },
  created () {
    this.getAllSSRPoints()
  },
  computed: {
    sortedArrayUser: function () {
      let sorted = []
      for (let i = 0; i < this.ptfs.length; i++) {
        sorted.push(this.ptfs[i])
      }
      if (this.search !== '') {
        let authorNameSearchString = this.search
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if (item !== null && typeof item.label !== 'undefined' && item.label !== null) {
            if (((item.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
              // console.log(item)
              return item
            }
          }
        })
      }
      return sorted
    }
  },
  methods: {
    closeSuccess (value) {
      this.isSuccess = value
      this.getAllSSRPoints()
    },
    openSuccessDelete (value) {
      this.isSuccess = value
      this.isConfirm = false
    },
    goBack (value) {
      this.isConfirm = value
    },
    openConfirmation (value) {
      this.isConfirm = true
      this.pointId = value
    },
    getAllSSRPoints () { // GET ALL SSR POINTS LISTS
      http.get(apiRoute.baseURL + apiRoute.getAllSSR, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.ptfs = response
        if (this.ptfs.length === 0) {
          this.isLoading = false
          this.nothing = true
        } else {
          this.isLoading = false
          this.nothing = false
        }
        const loc = this.ptfs[0].location.split(',')
        if (Number(loc[0]) < Number(loc[1])) {
          this.coordinate.lng = Number(loc[0])
          this.coordinate.lat = Number(loc[1])
        } else {
          this.coordinate.lng = Number(loc[1])
          this.coordinate.lat = Number(loc[0])
        }
        this.$emit('updateLocation', this.coordinate)
        http.post(apiRoute.baseURL + apiRoute.getSSRDoctorByPoint, {
          ssrId: this.ptfs[0].id
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          // console.log(response)
          this.$emit('updateDoctor', response)
        }).catch(error => {
          console.log(error)
        })
      }).catch(error => {
        console.log(error)
      })
    },
    changePosition (index) { // UPDATE POINT INFO IN MAP AND LIST
      this.position = index
      this.$emit('updateLoader', true)
      const geo = this.ptfs[index].location.split(',')
      // console.log(geo)
      if (Number(geo[0]) < Number(geo[1])) {
        this.coordinate.lng = Number(geo[0])
        this.coordinate.lat = Number(geo[1])
      } else {
        this.coordinate.lng = Number(geo[1])
        this.coordinate.lat = Number(geo[0])
      }
      // console.log(this.coordinate)
      this.$emit('updateLocation', this.coordinate)
      // GET ALL DOCTORS LIST BY SSR POINT
      http.post(apiRoute.baseURL + apiRoute.getSSRDoctorByPoint, {
        ssrId: this.ptfs[index].id
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        // console.log(response)
        this.$emit('updateDoctor', response)
      }).catch(error => {
        console.log(error)
      })
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .selected_ptf ::v-deep {
    .cont {
      border-color: $pass_marron;
    }
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
