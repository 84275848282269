<script>
import PopupBase from '@/components/helper/add/popup-base.vue'
import ButtonBase from '@/components/helper/add/button-base.vue'
import http from '@/plugins/http'
import apiRoutes from '@/router/api-routes'

export default {
  name: 'confirm-delete-point',
  components: {
    ButtonBase,
    PopupBase
  },
  props: {
    pointId: Number
  },
  data () {
    return {
      loader: false
    }
  },
  methods: {
    deletePoint () {
      this.loader = true
      http.delete(apiRoutes.baseURL + apiRoutes.deleteSSR + this.pointId, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        console.log(response)
        this.$emit('openSuccessDelete', true)
      }).catch(error => {
        console.log(error)
        this.loader = false
      })
    }
  }
}
</script>

<template>
  <div class="confirm-delete-point">
    <popup-base>
      <div class="begin bg-white m-auto w-1/2 rounded-lg p-8">
        <div class="header flex items-center justify-between">
          <div class="header-title font-medium text-2xl text-black">Supprimer point SSR</div>
          <div class="delete-icon cursor-pointer" @click="$emit('goBack', false)">
            <svg-icon name="ic_close" original class="w-4 h-4"/>
          </div>
        </div><br>
        <div class="body mt-6">
          <div class="title">Voulez-vous vraiment supprimer ce point SSR</div><br>
          <div class="delete-button flex items-center justify-end mt-4">
            <div class="deletion">
              <button-base
                  :label="$t('deleteButton')"
                  :is-loading="loader"
                  @click.native="deletePoint"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/style/sass/variables";
.deletion::v-deep {
  .button-base {
    .page-button-real {
      background-color: #C43A2B !important;
    }
  }
}
</style>
