<template>
  <div class="add-point">
    <div class="begin">
      <div class="back cursor-pointer">
        <svg-icon
          name="ic_go-back"
          original
          class="w-8"
          @click="goBack"
        />
      </div><br>
      <div class="form bg-white rounded-lg p-8 m-auto w-3/4">
        <div class="title text-black text-xl font-medium">
          {{ $t('pointsMenu') }}
        </div><br>
        <div class="flex">
          <div class="part-1 w-1/2 mr-4">
            <div class="login-form">
              <div class="label mb-2">{{ $t('centerName') }}*</div>
              <div class="input">
                <label>
                  <input
                    v-model="name"
                    type="text"
                    class="ipt p-4 w-full rounded-lg border"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('phone') }}*</div>
              <div class="input">
                <label>
                  <input
                    v-model="phone"
                    type="tel"
                    class="ipt p-4 w-full rounded-lg border"
                    @keypress="isNumberOnly"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('email') }}*</div>
              <div class="input">
                <label>
                  <input
                    v-model="email"
                    type="email"
                    class="ipt p-4 w-full rounded-lg border"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('centerCategories') }}*</div>
              <div class="input">
                <multiselect
                    v-model="category"
                    :options="allCategories"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :searchable="false"
                    :show-labels="false"
                    placeholder=""
                    label="label"
                    track-by="id"
                >
                  <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                  >
                    <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp;!isOpen"
                    >
                      {{ values.length }} services sélectionnés
                    </span>
                  </template>
                </multiselect>
<!--                <div class="relative">
                  <label>
                    <select
                      v-model="category"
                      class="ipt appearance-none w-full border py-4 pl-4 pr-8 rounded-lg"
                    >
                      <option
                        v-for="(item, i) in allCategories"
                        :key="i"
                        :value="item.id"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </label>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                    <svg-icon
                      name="ic_dropdown"
                      original
                      class="w-4 h-4"
                    />
                  </div>
                </div>-->
              </div>
            </div><br>
            <div class="login-form relative">
              <div class="label mb-3">{{ $t('centerLogo') }} (Moins de 1Mo)</div>
              <image-uploader
                id="fileInput"
                :preview="true"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                :quality="0.9"
                :scaleRatio="1"
                capture="environment"
                accept="image/*"
                :debug="1"
                doNotResize="gif"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImage"
              >
                <label for="fileInput" slot="upload-label">
                  <div v-if="!hasImage" class="input">
                    <div class="ipt py-8 w-full border rounded-lg cursor-pointer">
                      <div class="icones mb-3">
                        <svg-icon
                          name="ic_add-image"
                          original
                          class="w-8 h-8 m-auto"
                        />
                      </div>
                      <div class="add text-center">{{ $t('selectionImage') }}</div>
                    </div>
                  </div>
                  <div v-if="hasImage" class="close-pic absolute cursor-pointer text-red-400 py-1 px-2 rounded-full bg-white w-fit shadow">+</div>
                </label>
              </image-uploader>
            </div><br>
            <div class="login-form relative">
              <div class="label mb-3">{{ $t('centerPicture') }} (Moins de 1Mo)</div>
              <image-uploader
                id="fileInputCenter"
                :preview="true"
                :className="['file-input', { 'file-input--loaded': hasImageCenter }]"
                :quality="0.9"
                :scaleRatio="1"
                capture="environment"
                accept="image/*"
                :debug="1"
                doNotResize="gif"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImageCenter"
              >
                <label for="fileInputCenter" slot="upload-label">
                  <div v-if="!hasImageCenter" class="input">
                    <div class="ipt py-8 w-full border rounded-lg cursor-pointer">
                      <div class="icones mb-3">
                        <svg-icon
                          name="ic_add-image"
                          original
                          class="w-8 h-8 m-auto"
                        />
                      </div>
                      <div class="add text-center">{{ $t('selectionImage') }}</div>
                    </div>
                  </div>
                  <div v-if="hasImageCenter" class="close-pic absolute cursor-pointer text-red-400 py-1 px-2 rounded-full bg-white w-fit shadow">+</div>
                </label>
              </image-uploader>
            </div><br>
          </div>
          <div class="part-2 w-1/2 ml-4">
            <div class="login-form">
              <div class="label mb-2">{{ $t('geographicalCoordinate') }}*</div>
              <div class="input flex items-center">
                <label class="mr-2">
                  <input
                    v-model="lng"
                    type="text"
                    :placeholder="$t('longitude')"
                    class="ipt p-4 w-full rounded-lg border"
                    @keypress="isNumberOnly"
                  >
                </label>
                <label class="ml-2">
                  <input
                    v-model="lat"
                    type="text"
                    :placeholder="$t('latitude')"
                    class="ipt p-4 w-full rounded-lg border"
                    @keypress="isNumberOnly"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('pointAddress') }}</div>
              <div class="input">
                <label>
                  <input
                    v-model="address"
                    type="text"
                    class="ipt p-4 w-full rounded-lg border"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('technicalReferenceName') }}*</div>
              <div class="input">
                <label>
                  <input
                    v-model="technicalReference"
                    type="text"
                    class="ipt p-4 w-full rounded-lg border"
                  >
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('offeredServices') }}*</div>
              <div class="input">
                <multiselect
                  v-model="offeredServices"
                  :options="options"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :searchable="false"
                  :show-labels="false"
                  placeholder=""
                  label="name"
                  track-by="name"
                >
                  <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                  >
                    <span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp;!isOpen"
                    >
                      {{ values.length }} services sélectionnés
                    </span>
                  </template>
                </multiselect>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('description') }}</div>
              <div class="input">
                <label>
                  <textarea
                    v-model="description"
                    rows="11"
                    class="ipt p-4 w-full rounded-lg border resize-none"
                  ></textarea>
                </label>
              </div>
            </div><br>
          </div>
        </div>
        <div
          v-if="error"
          class="text-red-500 text-center mt-2"
        >
          {{ errorAdmin }}
        </div>
      </div><br>
      <div class="login-button m-auto w-3/4 flex items-center justify-end pb-24">
        <div class="cancel-button mr-4">
          <button-base
            :label="$t('cancelButton')"
            background="#ECECEC"
            fontcolor="#000000"
            @click.native="goBack"
          />
        </div>
        <div class="add-button ml-4">
          <button-base
            :label="$t('addButton')"
            :is-loading="loader"
            @click.native="addNewPoint"
          />
        </div>
      </div>
    </div>
    <success-add
      v-if="successAdd"
      :message="messageSuccess"
      :button-label="$t('goBackAddMemberButton')"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import ButtonBase from '../add/button-base'
import SuccessAdd from '../success-add'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
import Multiselect from 'vue-multiselect'
export default {
  name: 'add-point',
  components: { SuccessAdd, ButtonBase, Multiselect },
  data () {
    return {
      name: '',
      description: '',
      location: '',
      technicalReference: '',
      phone: null,
      email: '',
      category: [],
      address: '',
      offeredServices: [],
      geographic: '',
      logoPictureId: '',
      allCategories: [],
      pictureId: '',
      successAdd: false,
      error: false,
      errorAdmin: '',
      loader: false,
      lng: '',
      lat: '',
      hasImage: false,
      image: null,
      hasImageCenter: false,
      imageCenter: null,
      messageSuccess: '',
      options: [
        { name: 'Consultation', language: 'Consultation' },
        { name: 'Conseils', language: 'Conseils' },
        { name: 'Orientation', language: 'Orientation' },
        { name: 'Dépistage', language: 'Dépistage' },
        { name: 'PEC PF', language: 'PEC PF' },
        { name: 'PEC VBG', language: 'PEC VBG' }
      ]
    }
  },
  created () {
    this.getSSRCategories()
  },
  methods: {
    initiate () {
      this.$refs.image.$el.children[1].value = ''
      this.image = null
    },
    setImage: function (output) { // FUNCTION TO COMPRESS IMAGE OF LOGO
      this.hasImage = true
      this.image = output
      console.log('Info', output.info)
      console.log('Exif', output.exif)
      this.createFileIdLogo(this.image.dataUrl)
    },
    setImageCenter: function (output) { // FUNCTION TO COMPRESS IMAGE OF CENTER
      this.hasImageCenter = true
      this.imageCenter = output
      console.log('Info', output.info)
      console.log('Exif', output.exif)
      this.createFileIdCenter(this.imageCenter.dataUrl)
    },
    addNewPoint () { // ADD NEW POINT SSR
      if (this.name === '' || !this.phone || this.email === '' || this.lng === '' || this.lat === '' || this.technicalReference === '' || this.category.length === 0 || this.offeredServices.length === 0) {
        this.error = true
        this.errorAdmin = this.$t('errorAddAdmin')
      } else {
        this.loader = true
        this.error = false
        const categories = []
        for (const i in this.category) {
          categories.push(this.category[i].id)
        }
        this.location = this.lng.replace(',', '.') + ',' + this.lat.replace(',', '.')
        http.post(apiRoute.baseURL + apiRoute.createSSR, {
          label: this.name,
          description: this.description,
          location: this.location,
          pictureId: this.pictureId,
          offeredServices: this.offeredServices,
          technicalReferent: this.technicalReference,
          phone: this.phone,
          email: this.email,
          body: {},
          categorieId: categories,
          logoId: this.logoPictureId,
          adresse: this.address
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          console.log(response)
          this.successAdd = true
          this.messageSuccess = this.$t('newPointSSRAddSuccess') + 'Email: <b>' + this.email + '</b><br> Mot de passe: <b>' + response.password + '</b>'
        }).catch(error => {
          console.log(error)
          if (error.status === 409) {
            this.error = true
            this.errorAdmin = this.$t('createPointEmailError')
          }
          this.loader = false
        })
      }
    },
    getSSRCategories () { // GET ALL SSR CATEGORIES
      http.post(apiRoute.baseURL + apiRoute.getCategoryByType, {
        type: 'SSR'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        console.log(response)
        this.allCategories = response
      }).catch(error => {
        console.log(error)
      })
    },
    createFileIdCenter (filename) { // SEND IMAGE TO GET ID
      return new Promise((resolve, reject) => {
        http.post(apiRoute.baseURL + apiRoute.createFile, {
          file: filename
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          console.log(response)
          this.pictureId = response.id
          resolve('completed')
        }).catch(error => {
          console.log(error)
        })
      })
    },
    createFileIdLogo (filename) { // SEND IMAGE TO GET ID
      return new Promise((resolve, reject) => {
        http.post(apiRoute.baseURL + apiRoute.createFile, {
          file: filename
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          console.log(response)
          this.logoPictureId = response.id
          resolve('completed')
        }).catch(error => {
          console.log(error)
        })
      })
    },
    closeSuccess () { // CLOSE SUCCESS ADD POPUP
      this.successAdd = false
      this.goBack()
    },
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // alert(keyCode)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    goBack () { // GO BACK TO SSR LIST
      this.$emit('clickGoBack', false)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .add-point {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .ipt {
    background-color: $light_gray3;
    color: black;
  }
  .svg-fill {
    fill: transparent;
  }
  .close-pic {
    width: fit-content;
    top: 15px;
    right: -10px;
  }
  .login-form::v-deep {
    #fileInput, #fileInputCenter {
      display: none !important;
    }
    img {
      border: 1px solid #E2E8F0;
      border-radius: 0.5rem;
      height: 8rem;
    }
  }
  .input::v-deep {
    .multiselect {
      .multiselect__tags, .multiselect__single {
        background-color: $light_gray3 !important;
        color: black !important;
        font-size: 14px !important;
      }
      .multiselect__tags {
        padding: 14px 40px 14px 8px;
        border-radius: 0.5rem;
        border-color: #e2e8f0;
      }
      .multiselect__select {
        right: 0px;
        top: 4px;
      }
      .multiselect__single {
        margin-bottom: 0;
      }
    }
  }
</style>
