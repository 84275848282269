<template>
  <div class="point-list">
    <div class="table-header text-black bg-white rounded shadow px-6 py-4 mt-1 flex items-center cursor-pointer">
      <div class="flex-one item-color w-2/5 mr-4">
        <div class="flex items-center">
          <div
            :style="bagroundDefine"
            class="float-left w-8 h-8 text-center pt-2 -mt-1 mr-2 rounded-full first-letter-section text-sm font-bold text-white"
          >
            <div v-if="pointsLists.firstName === ''">{{ getFirstLetter(pointsLists.pseudo) }}</div>
            <div v-else>{{ getFirstLetter(pointsLists.firstName + ' ' + pointsLists.lastName) }}</div>
          </div>
          <div class="name">
            <div v-if="pointsLists.firstName === ''">{{ pointsLists.pseudo }}</div>
            <div v-else>{{ pointsLists.firstName + ' ' + pointsLists.lastName }}</div>
          </div>
        </div>
      </div>
      <div class="flex-one item-color w-1/5 mr-4">
        <div v-if="pointsLists.phone === null">-</div>
        <div v-else>{{ pointsLists.phone.substr(3) }}</div>
      </div>
      <div class="flex-one item-color w-1/3 mr-4">
        <div v-if="pointsLists.email === null">-</div>
        <div v-else>{{ pointsLists.email }}</div>
      </div>
      <div class="flex-one item-color w-1/12">
        <div v-if="pointsLists.sexe === null">-</div>
        <div v-else>{{ pointsLists.sexe }}</div>
      </div>
      <div class="flex-one item-color w-1/3">
        <div v-if="pointsLists.specialite === null">-</div>
        <div v-else>{{ pointsLists.specialite }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'point-list',
  props: {
    pointsLists: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      name: this.pointsLists.firstName + ' ' + this.pointsLists.lastName,
      bagroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845', '#000080']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.bagroundDefine.backgroundColor = colors[value]
    } else {
      this.bagroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .table-header:hover {
    background: rgba(1,101,200,0.1);
    border-left: 2px solid $pass_marron;
    box-shadow: -6px 0px 6px 0px rgba(51, 50, 50, 0.45);
  }
  .benin {
    width: fit-content;
  }
  .img {
    background-image: url("../../../assets/images/img_card.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
