<template>
  <div class="maps-point">
    <div class="begin w-full">
      <gmaps-map :options="{ center: { lat: parseFloat(pointLocation.lat), lng: parseFloat(pointLocation.lng) }, zoom: 12 }">
        <gmaps-marker :position="{ lat: parseFloat(pointLocation.lat), lng: parseFloat(pointLocation.lng) }" />
      </gmaps-map>
    </div>
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
export default {
  name: 'maps-point',
  components: { gmapsMap, gmapsMarker },
  props: {
    pointLocation: Object
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .begin {
    height: 77.5vh;
  }
  .gmaps-map {
    position: sticky;
    width: 100%;
    height: 100%;
  }
</style>
