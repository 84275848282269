<template>
  <div class="points">
    <div v-if="!addNewPoint" class="begin">
      <div class="aff flex">
        <div class="part-1 w-1/3 border-r bg-white h-screen overflow-y-scroll px-4 py-12">
          <div class="is-card">
            <is-ptf
              @updateLocation="updateLocation"
              @updateDoctor="updateDoctor"
              @updateLoader="updateLoader"
            />
          </div>
        </div>
        <div class="part-2 w-full">
          <div class="header bg-white py-6 pr-16 px-8 flex items-center justify-between">
            <div class="choix flex items-center text-black text-sm">
              <div
                class="in-card mr-6 cursor-pointer"
                :class="{'selected_choice': position === 1}"
                @click="changePosition(1)"
              >
                {{ $t('seeInCard') }}
              </div>
              <div
                class="in-card cursor-pointer"
                :class="{'selected_choice': position === 2}"
                @click="changePosition(2)"
              >
                {{ $t('seeInList') }}
              </div>
            </div>
            <div class="add-button">
              <button-base
                :label="$t('addPointButton')"
                @click.native="openNewAddPoint"
              />
            </div>
          </div><br>
          <div class="body">
            <div v-if="position === 1" class="map">
              <maps-point
                :point-location="location"
              />
            </div>
            <div v-if="position === 2" class="list">
              <div class="user-list bg-white rounded font-semibold shadow p-6 flex items-center">
                <div class="flex-one item-color w-2/5">{{ $t('completeName') }}</div>
                <div class="flex-one item-color w-1/5">{{ $t('phone') }}</div>
                <div class="flex-one item-color w-1/3">{{ $t('email') }}</div>
                <div class="flex-one item-color w-1/12">{{ $t('sex') }}</div>
                <div class="flex-one item-color w-1/3">{{ $t('profession') }}</div>
              </div>
              <div class="loading" :class="{ 'not_empty': isLoading === false }">
                <loading
                  :active="isLoading"
                  :can-cancel="true"
                  :on-cancel="onCancel"
                  :is-full-page="fullPage"
                  loader="dots"
                  color="#0B5382"
                  background-color="transparent"
                />
              </div>
              <div
                v-if="nothing && !isLoading"
                class="no-student text-center text-lg py-32"
              >
                {{ $t('noMemberPoint') }}
              </div>
              <div v-if="!isLoading" class="lister">
                <point-list
                  v-for="(item, i) in points"
                  :key="i"
                  :points-lists="item"
                  @click.native="openDetail(i)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- POINT SSR MEMBER DETAIL -->
    <point-member-detail
      v-if="memberDetail"
      :show-button="false"
      :member-detail-infos="isMemberDetail"
      @closeMembershipDetail=closeMembershipDetail
    />
    <!-- ADD NEW POINT SSR PAGE -->
    <add-point
      v-if="addNewPoint"
      @clickGoBack="clickGoBack"
    />
  </div>
</template>

<script>
import PointList from '../../components/helper/points/point-list'
import IsPtf from '../../components/helper/points/is-ptf'
import MapsPoint from '../../components/helper/points/maps-point'
import PointMemberDetail from '../../components/helper/points/point-member-detail'
import ButtonBase from '@/components/helper/add/button-base'
import AddPoint from '@/components/helper/points/add-point'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  name: 'index',
  components: { AddPoint, ButtonBase, Loading, PointMemberDetail, MapsPoint, IsPtf, PointList },
  data () {
    return {
      memberDetail: false,
      position: 1,
      location: {},
      isLoading: true,
      fullPage: false,
      points: [],
      isMemberDetail: {},
      nothing: false,
      addNewPoint: false
    }
  },
  methods: {
    clickGoBack (value) { // CLOSE ADD NEW POINT SSR PAGE
      this.addNewPoint = value
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    openNewAddPoint () { // OPEN ADD NEW POINT SSR PAGE
      this.addNewPoint = true
    },
    updateLoader (val) {
      this.isLoading = val
    },
    updateDoctor (val) {
      this.points = val
      // console.log(val)
      if (this.points.length === 0) {
        this.isLoading = false
        this.nothing = true
      } else {
        this.isLoading = false
        this.nothing = false
      }
    },
    updateLocation (val) {
      this.location = val
    },
    openDetail (index) {
      this.memberDetail = true
      this.isMemberDetail = this.points[index]
      // console.log(this.points[index])
    },
    closeMembershipDetail (val) {
      this.memberDetail = val
    },
    changePosition (index) { // CHANGE POSITION FUNCTION
      this.position = index
      if (index === 2) {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
      }
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .part-1 {
    height: 90vh;
  }
  .selected_choice {
    background: $pass_yellow;
    padding: 1rem 2rem;
    border-radius: 9999px;
  }
  .list {
    padding: 2rem 4rem 4rem 1.5rem;
  }
  .user-list {
    color: $light_gray;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
